import { useCallback, useMemo } from "react";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { useConfigStore, useOrganizationStore } from "../../../Store/store";

const BillingPackages = () => {
  document.title = "Billing | MarketBuzz";
  const [config] = useConfigStore((state) => [state.config]);

  const [organization] = useOrganizationStore((state) => [state.organization]);

  const packages = useMemo(() => {
    return config?.packages ?? [];
  }, [config]);

  const handleSubscribe = useCallback(() => {
    //open clover on a new tab
    window.open(
      `https://clover.com/appmarket/m/${organization.referenceId}/apps/${organization.appReferenceId}`,
      "_blank"
    );
  }, [organization]);

  return (
    <div className="mt-4">
      <Card className="border shadow-none">
        <CardBody>
          <h6 className="mb-4 mt-2">
            Available packages to subscribe on Clover
          </h6>

          <Row>
            {packages.map((pkg) => {
              const pkgId = pkg.packageId.toString();
              return (
                <Col key={pkgId} md={3} className="mb-3">
                  <Card className="border border-primary">
                    <CardBody>
                      <h4>{pkg.name}</h4>
                      <div>
                        <strong>
                          ${(pkg.amount / 100).toFixed(2)} (
                          {pkg.interval?.toLowerCase()})
                        </strong>
                      </div>
                      {pkg.credits && (
                        <div className="mt-1">{pkg.credits} credits</div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
            {packages.length === 0 && (
              <div className="text-center">No packages found</div>
            )}
          </Row>
          <Button color="success" onClick={handleSubscribe}>
            Subscribe on Clover
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default BillingPackages;
